<template>
    <v-btn
            :class="classes"
            :disabled="disabled"
            :loading="loadingPrintData"
            @click="clickPrintData(name)"
    >
        <span style="color:#fff;">{{ name }}</span>
    </v-btn>
</template>

<script>
    import axios from "axios";
    import {saveBlobFile} from "@/utils/saveFile";
    import {mapActions} from "vuex";

    export default {
        name: "cardPrinDocumentBtn",
        props: {
            name: {
                type: String,
                default: 'Приложение'
            },
            link: {
                type: String,
                default: '',
            },
            type: {
                type: String,
                default: 'success'
            },
            fileTypeName: {
                type: String,
                default: '.pdf'
            },
            fileType: {
                type: String,
                default: "application/pdf"
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                loadingPrintData: false,
            }
        },
        computed: {
            classes() {
                switch (this.type) {
                    case 'success':
                        return 'success__v-btn';
                    case 'blue':
                        return 'blue__v-btn';
                    case 'white':
                        return 'white__v-btn';
                    default:
                        return 'success__v-btn';
                }
            }
        },
        methods: {
            ...mapActions('notifications', {addNotification: 'addNotification'}),
            async clickPrintData(name = 'ReportFunc') {
                this.loadingPrintData = true;
                try {
                    const responseAxios = await axios.get(this.link, {
                        headers: this.$scripts.Utils.authHeader(),
                        responseType: 'blob'
                    });
                    if (responseAxios.status === 200) {
                        this.loadingPrintData = false;
                        saveBlobFile(responseAxios.data, name + this.fileTypeName, this.fileType)
                    } else {
                        this.loadingPrintData = false;
                        await this.addNotification({
                            notificationStatus: 'error',
                            notificationMessage: 'Ошибка скачивания файла ' + this.name,
                            timeout: 3000
                        })
                    }
                } catch (e) {
                    this.loadingPrintData = false;
                    await this.addNotification({
                        notificationStatus: 'error',
                        notificationMessage: 'Ошибка скачивания файла ' + this.name,
                        timeout: 3000
                    })
                }
            }
        },
    }
</script>

<style scoped>

</style>