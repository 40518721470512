<template>
    <div>
      <CardInput
          v-if="editMode"
          v-model="personToChange"
          :ignore-personal-phone-number="ignorePersonalPhoneNumber"
          v-on:input="requestPersonUpdate($event)"
          :saving-data="savingData"
          @cancel="onCancel"
      />
  <div v-else>
    <CardInfo v-if="personInitial" :data="personToShow"/>
    <div class="d-flex justify-end mb-1">
      <v-btn v-if="!blocked && !isSingle" class="button blue__v-btn mr-2" @click="addContact">
        <slot name="addContactText">Добавить новый контакт</slot>
      </v-btn>
      <v-btn v-if="!blocked" :loading="loadingContacts" class="button blue__v-btn mr-2" @click="editMode = !editMode">Редактировать</v-btn>
      <v-btn v-if="!blocked && canBeDeleted" class="button error__v-btn" @click="deleteContact">Удалить</v-btn>
    </div>

    <!--    <button v-if=" !get_blockedAll" class="button info-card__button">Редактировать</button>-->
    <!-- authData.role.id === 1 &&
      authData.role.id !== 1 &&-->
  </div>
    </div>
</template>

<script>
// import FormKcpContestInfoPersonContact from '@/components/forms/custom/formsKcpContestInfo/FormKcpContestInfoPersonContact';
import CardInfo from '@/components/contacts/CardInfo';
import CardInput from "@/components/contacts/CardInput";
// import request from "@/services/request";

export default {
  components: {
    CardInput,
    // FormKcpContestInfoPersonContact,
    CardInfo
  },
  props: {
    isSingle: {
      type: Boolean,
      default: false
    },
    savingData:{
      type: Boolean,
      default: false
    },
    loadingContacts:{
      type: Boolean,
      default: false
    },
    ignorePersonalPhoneNumber: {
      type: Boolean,
      default: false
    },
    blocked: {
      type: Boolean,
      default: false
    },
    canBeDeleted: {
      type: Boolean,
      default: true
    },
    contact: {
      type: Object,
      default: () => {
        return {
          fio: '',
          fioRodPad: '',
          email: '',
          position: '',
          positionRodPad: '',
          workPhoneNumber: '',
          personalPhoneNumber: '',
        }
      }
    },
  },
  data() {
    return {
      editMode: false,
      personToChange: this.contact,
      personInitial: this.contact,
    }
  },
  watch: {
    contact(newValue) {
      this.personToChange = {...newValue};
      this.personInitial = {...newValue};
    }
  },
  computed: {
    get_blockedAll() {
      return false
    },
    personToShow() {
      const {fio, position, email, workPhoneNumber, personalPhoneNumber,extensionNumber} = this.contact;
      return {
        'Фамилия Имя Отчество': fio,
        'Должность': position,
        'Контактный e-mail': email,
        'Номер рабочего телефона': workPhoneNumber,
        'Добавочный номер': extensionNumber,
        'Номер мобильного телефона': personalPhoneNumber,
      }
    },
  },
  methods: {
    addContact() {
      this.$emit('add-contact')
    },
    deleteContact() {
      this.$emit('delete-contact', this.contact)
    },
    async requestPersonUpdate(obj) {
      await this.$emit('change-contact', obj)
      this.editMode = false;
    },
    onCancel() {
      this.personToChange = {...this.personInitial};
      this.editMode = false;
    },
  },
}
</script>

<style lang="sass">
.info-card__button
  margin-top: 10px
  margin-left: 10px
</style>