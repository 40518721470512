<template>
  <div :class="['collapse', customClass]">
    <div class="collapse__toggle" @click="isOpen = !isOpen">
      <div class="collapse__title">{{ title }}</div>
      <v-icon :size="30" color="#00599b" class="collapse__icon"
      >mdi-chevron-down
      </v-icon
      >
    </div>
    <transition name="fade">
      <div class="collapse__content" v-if="isOpen">
        <div ref="collapseContent">
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Открыть",
    },
    customClass: {
      type: String,
    },
    initialOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: this.initialOpen,
    };
  },
  computed: {
    heightCollapsedContent() {
      if (this.isOpen) {
        return this.$refs.collapseContent.clientHeight;
      } else {
        return 0;
      }
    },
  },
};
</script>

<style lang="sass">
@import "@/assets/styles/utils/variables"
.collapse__content
  height: auto
  overflow: hidden
.fade-enter-active, .fade-leave-active
  transition: opacity .5s
.fade-enter, .fade-leave-to
  opacity: 0

.collapse__toggle
  display: flex
  justify-content: space-between
  align-items: center
  cursor: pointer
  margin-bottom: 28px

.collapse__title
  font-family: Roboto, sans-serif
  color: $primary-color
  text-transform: uppercase
  font-weight: 600
  font-size: 18px
  line-height: 21.94px
</style>