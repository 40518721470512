<!-- Компонент основного заголовка страницы, может принимать в slot доп.контент -->
<template>
<header class="main-header">
	<h1 v-if="title" class="main-header__title" itemprop="headline">{{title}}</h1>
	<h2 v-if="subtitle" class="main-header__subtitle">{{subtitle}}</h2>
	<slot></slot>
</header>
</template>
<script>
export default {
	props: {
		title: {
			type: String,
		},
		subtitle: {
			type: String,
		}
	},
}
</script>
<style lang="sass">
.main-header
	margin-bottom: 20px
.main-header__title
	font-size: 32px
	line-height: 35.2px
	color: #00599B
	font-family: Roboto, sans-serif
	font-weight: 700
	max-width: 1200px
	margin-bottom: 22px
.main-header__subtitle
	color: #323C47
	font-family: Roboto, sans-serif
	font-size: 19px
	font-weight: 300
	line-height: 20.9px
</style>